// @flow

/**
 * Module dependencies.
 */

import { graphql, useStaticQuery } from 'gatsby';
import { keys, map, pickBy } from 'lodash';
import { units } from '@seegno-labs/react-components/styles';
import IconButton from 'components/core/button/icon-button';
import React, { type Node, useMemo } from 'react';
import facebookIcon from 'assets/svg/social/facebook.svg';
import instagramIcon from 'assets/svg/social/instagram.svg';
import linkedinIcon from 'assets/svg/social/linkedin.svg';
import styled from 'styled-components';
import twitterIcon from 'assets/svg/social/twitter.svg';

/**
 * Social icons.
 */

const socialIcons = {
  facebookUrl: facebookIcon,
  instagramUrl: instagramIcon,
  linkedinUrl: linkedinIcon,
  twitterUrl: twitterIcon
};

/**
 * `List` styled component.
 */

const List = styled.ul`
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: ${units(3)};
  justify-content: center;
`;

/**
 * Social links query.
 */

const socialLinksQuery = graphql`
  query {
    socialLinks: allSeegnoCmsSetting {
      nodes {
        code
        value
      }
    }
  }
`;

/**
 * `SocialLinks` component.
 */

function SocialLinks(): Node {
  const { socialLinks } = useStaticQuery(socialLinksQuery);
  const socialIconsKeys = keys(socialIcons);
  const links = useMemo(() => pickBy(
    socialLinks.nodes,
    ({ code }) => socialIconsKeys.includes(code)
  ), [socialIconsKeys, socialLinks.nodes]);

  return (
    <List>
      {map(links, ({ code, value }) => value && (
        <li key={code}>
          <IconButton
            aria-label={code}
            colorTheme={'white'}
            href={value}
            icon={socialIcons[code]}
            rel={'noopener'}
            target={'_blank'}
          />
        </li>
      ))}
    </List>
  );
}

/**
 * Export `SocialLinks` component.
 */

export default SocialLinks;
