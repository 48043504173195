// @flow

/**
 * Module dependencies.
 */

import React, { type Node } from 'react';

/**
 * Constants.
 */

const width = 41.9;
const height = 43.8;
const scaleX = 1 / width;
const scaleY = 1 / height;

/**
 * `SlykMask` component.
 */

const SlykMask = (): Node => (
  <svg
    aria-hidden
    height={0}
    viewBox={`0 0 ${width} ${height}`}
    width={0}
  >
    <defs>
      <clipPath
        clipPathUnits={'objectBoundingBox'}
        id={'slyk-mask'}
        transform={`scale(${scaleX} ${scaleY})`}
      >
        <path d={'M38.9 6.8C32.6-2.3 9.2-2.3 3 6.8c-2 2.9-3 7.9-3 15.1s1 12.1 3 15c6 8.8 29.7 9.4 35.8.4 2-2.9 3-8.1 3-15.4.1-7.1-.9-12.2-2.9-15.1z'} />
      </clipPath>
    </defs>
  </svg>
);

/**
 * Export `SlykMask` component.
 */

export default SlykMask;
