// @flow

/**
 * Module dependencies.
 */

import { camelCase } from 'lodash';
import { color, units } from '@seegno-labs/react-components/styles';
import { graphql, useStaticQuery } from 'gatsby';
import { theme } from 'styled-tools';
import { useRecaptcha } from '@slyk/recaptcha';
import Form from 'components/core/forms/form';
import PhoneInput from './phone-input';
import React, { type Node, useCallback } from 'react';
import SubmitButton from 'components/core/forms/submit-button';
import axios from 'axios';
import caretIcon from 'slyk-design-system/assets/svgs/caret.svg';
import phoneNumberStyle from 'react-phone-input-2/lib/high-res.css';
import styled from 'styled-components';
import useBreakpoint from 'hooks/use-breakpoint';
import useTranslate from 'hooks/use-translate';

/**
 * Config query.
 */

const configQuery = graphql`
  query {
    config {
      api {
        slyk {
          baseUrl
          endpoints {
            joinPayspace
          }
        }
      }
      joinPayspace
      settings {
        recaptchaSiteKey
      }
    }
  }
`;

/**
 * `Props` type.
 */

type Props = {|
  className?: string,
  onClose: () => void,
  onNotification: ({ message: string, type: string }) => void
|};

/**
 * Json schema.
 */

const jsonSchema = {
  properties: {
    countryCode: { type: 'string' },
    phoneNumber: { type: 'string' }
  },
  required: ['phoneNumber'],
  type: 'object'
};

/**
 * Initial form values.
 */

const initialFormValues = {
  phoneNumber: '+1'
};

/**
 * `FormGroup` styled component.
 */

const FormGroup = styled.div`
  background-color: ${color('grey200')};
  border-radius: 6px;
  display: grid;
  grid-template-columns: 1fr max-content;
  margin-bottom: ${units(2)};

  ${phoneNumberStyle}

  .react-tel-input {
    .form-control {
      background: transparent;
      border: none;
      font-size: 16px;
      outline: none;
      padding-left: ${units(9)};
      padding-right: ${units(2)};
      width: 100%;

      &:focus {
        outline: 0;
      }
    }

    .selected-flag {
      padding-left: 16px;
      width: 52px;

      .arrow {
        background: url('data:image/svg+xml;utf8,${caretIcon}');
        border: none;
        height: ${units(2)};
        transform: translate(-3px, -4px);
        width: ${units(2)};

        &.up {
          border: none;
          transform: translate(-3px, -4px) rotate(180deg);
        }
      }

      &:hover,
      &:focus {
        background: transparent;
      }
    }

    .flag-dropdown {
      border: none;

      &.open,
      &.open .selected-flag,
      &:hover,
      &:focus {
        background: transparent;
        border-radius: 0;
      }
    }

    .country-list {
      border-radius: 6px;
      box-shadow: ${theme('boxShadow.dropdownMenu')};

      .flag {
        left: 13px;
        margin-top: -3px;
        top: 8px;
      }

      .divider {
        border-color: ${color('grey200')};
      }

      .country {
        outline: 0;
        padding-bottom: ${units(1)};
        padding-top: ${units(1)};
        position: relative;

        .dial-code {
          color: ${color('secondary')};
        }

        &.highlight,
        &:hover {
          background-color: ${color('blue100')};
        }
      }
    }
  }
`;

/**
 * `FlexCenter` styled component.
 */

const FlexCenter = styled.div`
  align-items: center;
  display: flex;
  grid-column: 1;
  grid-row: 1;
  justify-content: center;
  position: relative;
`;

/**
 * `StyledSubmitButton` styled component.
 */

const StyledSubmitButton = styled(SubmitButton).attrs({
  size: 'small'
})`
  grid-column: 2;
  grid-row: 1;
  line-height: 21px;
  margin: 6px;
  padding: ${units(1.25)} ${units(2.25)};
  width: 110px;
`;

/**
 * `PhoneInputForm` component.
 */

function PhoneInputForm(props: Props): Node {
  const { className, onClose, onNotification } = props;
  const translate = useTranslate();
  const {
    config: {
      api: {
        slyk
      },
      joinPayspace,
      settings: {
        recaptchaSiteKey
      }
    }
  } = useStaticQuery(configQuery);

  const isDesktop = useBreakpoint('md', 'min');
  const handleJoinPayspace = useCallback(values => {
    const { reset, ...rest } = values;
    const apiBaseUrl = slyk?.baseUrl;
    const joinPayspaceEndpoint = slyk?.endpoints?.joinPayspace;

    return axios
      .post(`${apiBaseUrl}${joinPayspaceEndpoint}`, { ...rest }, {
        headers: {
          'Content-Type': 'application/json',
          'x-payspace-slug': joinPayspace
        }
      })
      .then(() => {
        onNotification({
          message: translate('earlyAccessModal.form.success'),
          type: 'success'
        });

        onClose();
        reset();
      })
      .catch(error => {
        const errorCode = camelCase(error?.response.data?.code);
        const basePath = 'earlyAccessModal.form.errors.network';
        const errorMessage = translate(`${basePath}.${errorCode}`) ?? translate(`${basePath}.defaultError`);

        onNotification({
          message: errorMessage,
          type: 'error'
        });
      });
  }, [joinPayspace, onClose, onNotification, slyk, translate]);

  const {
    isRecaptchaExecuting: isLoading,
    renderRecaptcha,
    submitWithRecaptcha
  } = useRecaptcha({
    onSubmit: handleJoinPayspace,
    recaptchaSiteKey
  });

  const handleSubmit = useCallback(({ countryCode, phoneNumber }, { reset }) => {
    const values = {
      countryCode,
      phoneNumber: `+${phoneNumber}`,
      reset
    };

    return submitWithRecaptcha(values);
  }, [submitWithRecaptcha]);

  return (
    <Form
      className={className}
      initialValues={initialFormValues}
      jsonSchema={jsonSchema}
      onSubmit={handleSubmit}
    >
      <FormGroup>
        <FlexCenter>
          <PhoneInput
            countryCodeField={'countryCode'}
            disabled={isLoading}
            name={'phoneNumber'}
            placeholder={translate('earlyAccessModal.form.placeholder')}
          />
        </FlexCenter>

        <StyledSubmitButton isSubmitting={isLoading}>
          {translate('earlyAccessModal.form.buttonLabel')}
        </StyledSubmitButton>
      </FormGroup>

      {isDesktop && renderRecaptcha()}
    </Form>
  );
}

/**
 * Export `PhoneInputForm` component.
 */

export default PhoneInputForm;
