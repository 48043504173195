// @flow

/**
 * Export `regex`.
 */

export default {
  hasNumbers: /\d/,
  tags: /<\d>(.*?)<\/\d>/g
};
