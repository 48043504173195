// @flow

/**
 * Module dependencies.
 */

import GatsbyBackgroundImage from 'gatsby-background-image';
import React from 'react';

/**
 * Background styles.
 */

const backgrounStyles = {
  bottom: 0,
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0
};

/**
 * `BackgroundImage` component.
 */

const BackgroundImage = ({ style, ...rest }: Object) => (
  <GatsbyBackgroundImage
    {...rest}
    style={{ ...backgrounStyles, ...style }}
  />
);

/**
 * Export `BackgroundImage` component.
 */

export default BackgroundImage;
