// @flow

/**
 * Module dependencies.
 */

import { color, units } from '@seegno-labs/react-components/styles';
import { isHash } from 'utils/url-resolver';
import { prop } from 'styled-tools';
import Button from 'components/core/button';
import Link from 'components/core/links/link';
import React, { type Node } from 'react';
import RouterLink from 'components/core/links/router-link';
import styled from 'styled-components';

/**
 * Export `ItemLink` type.
 */

export type ItemLink = {
  href?: ?string,
  isButton?: boolean,
  label: string,
  target: ?string,
  to?: ?string
};

/**
 * `Props` type.
 */

type Props = {
  closeMobileMenu?: () => void,
  links: Array<ItemLink>,
  linksMarginRight?: string | Function
};

/**
 * `List` styled component.
 */

const List = styled.ul`
  white-space: nowrap;
`;

/**
 * `ListItem` styled component.
 */

const ListItem = styled.li`
  display: inline-block;

  &:not(:last-child) {
    margin-right: ${prop('marginRight', units(5))};
  }
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  &[aria-current='page'] {
    color: ${color('grey400')};
    pointer-events: none;
  }

  &:focus,
  &:hover {
    color: ${color('primary')};
  }
`;

/**
 * `LinksList` component.
 */

function LinksList(props: Props): Node {
  const { closeMobileMenu, links, linksMarginRight } = props;
  const lastIndex = links.length - 1;

  return (
    <List>
      {links.map(({ href, isButton, label, target, to }, index) => {
        const Component = isButton ? Button : StyledLink;

        return (!!href || !!to) && (
          <ListItem
            key={label}
            marginRight={linksMarginRight}
          >
            <Component
              as={to ? RouterLink : null}
              colorTheme={lastIndex === index ? 'secondary' : 'grey'}
              href={href}
              onClick={closeMobileMenu}
              rel={href && !isHash(href) ? 'noopener' : null}
              size={isButton ? 'medium' : 'large'}
              target={target ?? (href && !isHash(href) ? '_blank' : null)}
              to={to}
              variant={lastIndex === index ? 'fill' : 'outline'}
            >
              {label}
            </Component>
          </ListItem>
        );
      })}
    </List>
  );
}

/**
 * Export `LinksList` component.
 */

export default LinksList;
