// @flow

/**
 * Module dependencies.
 */

import { media } from '@seegno-labs/react-components/styles';
import Button from 'slyk-design-system/button';
import styled from 'styled-components';

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  align-items: center;
  display: inline-flex;
  justify-content: center;

  ${media.max('sm')`
    width: 100%;
  `}
`;

/**
 * Export `StyledButton` component.
 */

export default StyledButton;
