// @flow

/**
 * Module dependencies.
 */

import React, { type ComponentType, type Node } from 'react';
import regex from 'utils/regex';
import useTranslate from 'hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  components: Array<ComponentType<any>>,
  keyPath: string
|};

/**
 * React string replace.
 */

function reactStringReplace(
  value: string,
  regex: string,
  renderer: (string, number) => Node
): Array<Node> {
  return value
    .split(regex)
    .map((item, index) => {
      if (index % 2) {
        return renderer(item, index);
      }

      return (
        <span
          key={index}
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {item}
        </span>
      );
    });
}

/**
 * `Interpolate` component.
 */

function Interpolate({ components, keyPath }: Props): Array<Node> {
  const translate = useTranslate();
  const translatedValue = translate(keyPath);

  return reactStringReplace(translatedValue, regex.tags, (match, index) => {
    const Component = components[index];

    return !Component ? match : <Component key={index}>{match}</Component>;
  });
}

/**
 * Export `Interpolate` component.
 */

export default Interpolate;
