// @flow

/**
 * Module dependencies.
 */

import { Box, Fill, gutterStyles } from 'components/core/layout';
import { Icon } from '@seegno-labs/react-components/media';
import { color, media, units } from '@seegno-labs/react-components/styles';
import { concat, find, map } from 'lodash';
import { graphql, useStaticQuery } from 'gatsby';
import { ifProp, theme } from 'styled-tools';
import LinksList, { type ItemLink } from './links-list';
import MobileMenu from './mobile-menu';
import Navicon from './navicon';
import React, { type Node, useCallback, useMemo, useState } from 'react';
import RouterLink from 'components/core/links/router-link';
import logoIcon from 'assets/svg/logo.svg';
import styled, { css } from 'styled-components';
import useScroll from 'hooks/use-scroll';
import useTranslate from 'hooks/use-translate';

/**
 * `Name` type.
 */

type Name = 'navbar-center' | 'navbar-right';

/**
 * `Nav` styled component.
 */

const Nav = styled.nav`
  ${gutterStyles}

  align-items: center;
  display: flex;
  left: 0;
  padding: ${units(2)};
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${theme('zIndex.navbar')};

  ${media.min('sm')`
    padding: ${units(3)};
  `}

  ${media.min('md')`
    left: 122px;
  `}
`;

/**
 * `Underlay` styled component.
 */

const Underlay = styled(Fill)`
  background-color: ${color.transparentize('white', 0.95)};
  left: -122px;
  transform: translateY(-100%);
  transition: transform ${theme('animations.fastTransition')};
  z-index: -1;

  ${ifProp('isVisible', css`
    transform: translateY(0);
  `)}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

/**
 * `LogoLink` styled component.
 */

const LogoLink = styled(RouterLink)`
  color: ${color('primary')};
  left: 0;
  line-height: 0;
  padding: 21px 0 0 ${units(2)};
  position: fixed;
  text-decoration: none;
  top: 0;
  transition: color ${theme('animations.defaultTransition')};
  z-index: 1000;

  ${ifProp('active', css`
    pointer-events: none;
  `)}

  ${media.min('xs')`
    padding-left: ${units(3)};
  `}

  ${media.min('sm')`
    padding-top: ${units(3)};
  `}

  ${media.min('md')`
    padding: ${units(4.25)} 0 0 ${units(3)};
  `}
`;

/**
 * `DesktopLinks` styled component.
 */

const DesktopLinks = styled.div`
  display: none;
  flex: 1;
  justify-content: flex-start;

  ${media.min('md')`
    display: flex;
    padding-right: ${units(4)};
  `}

  @media (min-width: 1700px ) {
    left: calc((100vw - ${theme('breakpoints.xl')}px) / 2);
    padding-left: ${units(2)};
    position: absolute;
  }
`;

/**
 * `Logo` styled component.
 */

const Logo = styled(Icon).attrs({
  icon: logoIcon,
  size: '98px'
})`
  ${media.max('sm')`
    max-width: 72px;
  `}
`;

/**
 * Navbar links query.
 */

const navbarLinksQuery = graphql`
  query {
    navbarLinks: allSeegnoCmsMenuEntity(filter: {
      content: {
        name: {
          in: ["navbar-center", "navbar-right"]
        }
      }
    }) {
      nodes {
        content {
          list {
            isButton
            openInNewTab
            pageUrl
            title
            url
          }
          name
        }
      }
    }
  }
`;

/**
 * Normalize links by name.
 */

function normalizeLinksByName(menus: Array<Object>, name: Name): Array<ItemLink> {
  const menu = find(menus, { name });

  if (!menu) {
    return [];
  }

  return menu.list.map(({ isButton, openInNewTab, pageUrl, title, url }) => ({
    href: url,
    isButton,
    label: title,
    target: openInNewTab ? '_blank' : '_self',
    to: pageUrl
  }));
}

/**
 * `Navbar` component.
 */

function Navbar(): Node {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { navbarLinks } = useStaticQuery(navbarLinksQuery);
  const { yPos } = useScroll();
  const translate = useTranslate();
  const handleCloseMenu = useCallback(() => setMenuOpen(false), []);
  const toggleMenu = useCallback(() => setMenuOpen(open => !open), []);
  const { appLinks, links } = useMemo(() => {
    const menus = map(navbarLinks?.nodes, ({ content }) => content);

    return {
      appLinks: normalizeLinksByName(menus, 'navbar-right'),
      links: normalizeLinksByName(menus, 'navbar-center')
    };
  }, [navbarLinks.nodes]);

  return (
    <>
      <LogoLink
        aria-label={translate('navbar.home')}
        to={'/'}
      >
        <Logo aria-hidden />
      </LogoLink>

      <Nav>
        <Underlay isVisible={yPos > 90 && !isMenuOpen} />

        <Content>
          <DesktopLinks>
            <LinksList links={links} />
          </DesktopLinks>

          <Box
            display={'none'}
            displayMd={'block'}
          >
            <LinksList
              links={appLinks}
              linksMarginRight={units(2)}
            />
          </Box>

          <Box
            display={'block'}
            displayMd={'none'}
          >
            <Navicon
              active={isMenuOpen}
              onClick={toggleMenu}
            />
          </Box>
        </Content>
      </Nav>

      <MobileMenu
        closeMenu={handleCloseMenu}
        isOpen={isMenuOpen}
        links={concat(links, appLinks)}
      />
    </>
  );
}

/**
 * Export `Navbar` component.
 */

export default Navbar;
