// @flow

/**
 * Module dependencies.
 */

import { ErrorMessage } from 'slyk-design-system/forms/fields-styled-components';
import { useField } from '@seegno/react-forms';
import PhoneNumberInput from 'react-phone-input-2';
import React, { type Node, useCallback } from 'react';

/**
 * Preferred country.
 */

const preferredCountry = 'us';

/**
 * Excluded countries.
 */

const excludedCountries = ['cu', 'ir', 'kp', 'sy', 'ss'];

/**
 * `Props` type.
 */

type Props = {|
  countryCodeField: string,
  disabled: boolean,
  name: string,
  placeholder: string
|};

/**
 * `PhoneInput` component.
 */

function PhoneInput(props: Props): Node {
  const { countryCodeField, disabled, name, placeholder } = props;
  const { error, onChange, ...fieldProps } = useField(name);
  const {
    onChange: onCountryCodeChange,
    ...countryCodeFieldProps
  } = useField(countryCodeField);

  const handleChange = useCallback((value, { countryCode }) => {
    onChange(value);
    onCountryCodeChange(countryCode);
  }, [onChange, onCountryCodeChange]);

  return (
    <>
      <PhoneNumberInput
        {...fieldProps}
        country={preferredCountry}
        countryCodeEditable={false}
        disabled={disabled}
        excludeCountries={excludedCountries}
        inputProps={{ name }}
        onChange={handleChange}
        placeholder={placeholder}
        preferredCountries={[preferredCountry]}
        specialLabel={null}
      />

      <input
        {...countryCodeFieldProps}
        onChange={null}
        type={'hidden'}
      />

      <ErrorMessage visible={!!error}>
        {error}
      </ErrorMessage>
    </>
  );
}

/**
 * Export `PhoneInput` component.
 */

export default PhoneInput;
