// @flow

/**
 * Module dependencies.
 */

import { Box } from 'components/core/layout';
import { Image } from '@seegno-labs/react-components/media';
import { Modal, type ModalProps } from 'components/core/modal';
import { Type } from '@seegno-labs/react-components/typography';
import { UrlResolver } from '@slyk/url-resolver';
import { color, columnWidth, media, units } from '@seegno-labs/react-components/styles';
import { graphql, useStaticQuery } from 'gatsby';
import { range } from 'lodash';
import Bowser from 'bowser';
import Button from 'components/core/button';
import Message from 'components/core/message';
import PhoneInputForm from './phone-input-form';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node, useState } from 'react';
import qrCodeImage from 'assets/images/qr-code@2x.webp';
import styled from 'styled-components';
import useBreakpoint from 'hooks/use-breakpoint';
import useTranslate from 'hooks/use-translate';

/**
 * Config query.
 */

const configQuery = graphql`
  query {
    config {
      app {
        domain
      }
      joinPayspace
    }
  }
`;

/**
 * Get app download url.
 */

function getAppDownloadUrl(appDomain: string, payspaceSlug: string): string {
  const resolver = new UrlResolver({
    domain: appDomain,
    protocol: typeof window !== 'undefined' ? window.location.protocol : 'https:'
  });

  return resolver.resolve(payspaceSlug, '/get');
}

/**
 * Is facebook.
 */

function isFacebook(userAgent: string) {
  return userAgent.includes('FB_IAB/FB4A') || userAgent.includes('FBAN/FBIOS');
}

/**
 * Can download app.
 */

function canDownloadApp(): boolean {
  if (typeof window !== 'undefined') {
    const browser = Bowser.getParser(window.navigation.userAgent);

    return browser.some(['mobile', 'tablet']) && !isFacebook(browser.getUA());
  }

  return false;
}

/**
 * `Props` type.
 */

type Props = $Diff<ModalProps, { children: Node }>;

/**
 * `StyledModal` styled component.
 */

const StyledModal = styled(Modal)`
  padding: 0;

  ${media.max('xs')`
    grid-template-columns: 1fr;
    padding: 0 19px 0 18px;
  `};
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-template-areas: 
    'title'
    'info';
  grid-template-columns: 1fr;
  grid-template-rows: ${units(7)} max-content;
  padding: ${units(3)} ${units(2)} ${units(2)} 27px;

  ${media.min('md')`
    grid-column-gap: ${units(3)};
    grid-template-areas: 
      'title .'
      'info  image'
      'form  qrCode';
    grid-template-columns: minmax(auto, ${units(40)}) 1fr;
    grid-template-rows: repeat(3, max-content);
    padding: ${units(10)} 0 109px ${columnWidth(1.14)}%;
  `}

  ${media.min('lg')`
    grid-template-columns: minmax(auto, ${units(54.5)}) 1fr;
  `}
`;

/**
 * `InfoContent` styled component.
 */

const InfoContent = styled.div`
  grid-area: info;
  margin: ${units(3)} 0;

  ${media.min('md')`
    margin: ${units(9)} 0 0;
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H2).attrs({ as: 'h1' })`
  align-self: initial;
  color: ${color('secondary')};
  font-weight: 300;
  grid-area: title;

  ${media.max('md')`
    align-self: center;
    font-size: 32px;
    letter-spacing: -0.9px;
    line-height: 54px;
  `}
`;

/**
 * `InfoTitle` styled component.
 */

const InfoTitle = styled(Type.H4).attrs({ as: 'h2' })`
  color: ${color('primary')};
  padding-bottom: ${units(3)};
`;

/**
 * `List` styled component.
 */

const List = styled.ol`
  margin: 0;
  padding-left: ${units(2.25)};
`;

/**
 * `ListItem` styled component.
 */

const ListItem = styled.li`
  margin-bottom: ${units(2.5)};
`;

/**
 * `DownloadAppButton` styled component.
 */

const DownloadAppButton = styled(Button).attrs({ size: 'small' })`
  display: flex;
  line-height: 21px;
  margin: ${units(7.5)} ${units(-2)} 0 -27px;
  max-width: ${units(19)};
  min-height: ${units(5.5)};
  padding: ${units(1)} ${units(1.5)};
`;

/**
 * `FormWrapper` styled component.
 */

const FormWrapper = styled.div`
  grid-area: form;
  margin-top: ${units(-2.5)};

  ${media.max('md')`
    display: none;
  `}
`;

/**
 * `Label` styled component.
 */

const Label = styled(Type.Label).attrs({ as: 'div' })`
  color: ${color('secondary')};
  padding-bottom: ${units(3)};

  strong, b {
    font-weight: 700;
  }
`;

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled(Image)`
  align-self: end;
  display: none;
  grid-area: image;
  justify-self: end;
  max-width: 579px;
  width: 100%;

  ${media.min('md')`
    display: block;
    margin-top: ${units(9)};
  `}

  ${media.min('lg')`
    margin-top: 0;
  `}
`;

/**
 * `QRCodeLabel` styled component.
 */

const QRCodeLabel = styled(Type.Label).attrs({ as: 'div' })`
  color: ${color('secondary')};
  display: none;
  grid-area: qrCode;
  justify-self: center;
  height: max-content;
  margin-top: ${units(5.75)};

  > span > span {
    color: ${color('primary')};
  }

  strong, b {
    font-weight: 700;
  }

  ${media.min('md')`
    display: block;
    margin-left: ${units(-3)};
  `}

  ${media.min('xl')`
    margin-left: ${units(-8)};
  `}
`;

/**
 * `StyledPhoneInputForm` styled component.
 */

const StyledPhoneInputForm = styled(PhoneInputForm)`
  grid-area: form;
`;

/**
 * `EarlyAccessModal` component.
 */

function EarlyAccessModal({ isOpen, onClose }: Props): Node {
  const [notification, setNotification] = useState();
  const translate = useTranslate();
  const { config } = useStaticQuery(configQuery);
  const downloadAppUrl = getAppDownloadUrl(config?.app?.domain, config?.joinPayspace);
  const isMobile = useBreakpoint('md', 'max');

  return (
    <>
      <StyledModal
        isOpen={isOpen}
        onClose={onClose}
      >
        <Wrapper>
          <Title>
            {translate('earlyAccessModal.title')}
          </Title>

          <InfoContent>
            <InfoTitle>
              {translate('earlyAccessModal.info.title')}
            </InfoTitle>

            <List>
              {range(4).map(index => (
                <ListItem key={index}>
                  <Type.Label
                    as={'p'}
                    color={color('grey1000')}
                  >
                    <RawHtml>
                      {translate(`earlyAccessModal.info.list.item${index + 1}`)}
                    </RawHtml>
                  </Type.Label>
                </ListItem>
              ))}
            </List>

            {isMobile && canDownloadApp && (
              <Box
                display={'flex'}
                justifyContent={'center'}
              >
                <DownloadAppButton
                  href={downloadAppUrl}
                  rel={'noopener'}
                  target={'_blank'}
                >
                  {translate('earlyAccessModal.info.buttonLabel')}
                </DownloadAppButton>
              </Box>
            )}
          </InfoContent>

          <FormWrapper>
            <Label>
              <RawHtml>
                {translate('earlyAccessModal.downloadApp.label')}
              </RawHtml>
            </Label>

            <StyledPhoneInputForm
              onClose={onClose}
              onNotification={setNotification}
            />
          </FormWrapper>

          <QRCodeLabel>
            <RawHtml>
              {translate('earlyAccessModal.downloadApp.qrCodeLabel')}
            </RawHtml>
          </QRCodeLabel>

          <StyledImage defaultUrl={qrCodeImage} />
        </Wrapper>
      </StyledModal>

      {notification && (
        <Message
          onClose={() => setNotification(null)}
          type={notification?.type}
        >
          <RawHtml>
            {notification?.message}
          </RawHtml>
        </Message>
      )}
    </>
  );
}

/**
 * Export `EarlyAccessModal` component.
 */

export default EarlyAccessModal;
