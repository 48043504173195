// @flow

/**
 * Module dependencies.
 */

import { Box, Container } from '@seegno-labs/react-components/layout';
import { chunk } from 'lodash';
import { color, media, units } from '@seegno-labs/react-components/styles';
import { graphql, useStaticQuery } from 'gatsby';
import { theme } from 'styled-tools';
import BackgroundImage from 'components/core/images/background-image';
import Link from 'components/core/links/link';
import React, { type Node } from 'react';
import SocialLinks from './social-links';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children?: Node
};

/**
 * Footer section query.
 */

const footerQuery = graphql`
  query {
    image: file(relativePath: { eq: "images/footer-bg@2x.webp" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    footerMenu: allSeegnoCmsMenuEntity(filter: { content: { name: { eq: "footer" } } }) {
      nodes {
        content {
          list {
            openInNewTab
            pageUrl
            title
            url
          }
        }
      }
    }
  }
`;

/**
 * `FooterWrapper` styled component.
 */

const FooterWrapper = styled.footer`
  background-color: ${color('secondary')};
  color: ${color('white')};
  position: relative;
`;

/**
 * `Divider` styled component.
 */

const Divider = styled.div`
  background-color: ${color.transparentize('white', 0.1)};
  height: 1px;
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-gap: ${units(6)};
  grid-template-areas:
    'pagesLinks'
    'socialLinks'
    'copyright';
  grid-template-columns: 1fr;
  justify-items: center;
  padding: ${units(6)} 0;

  ${media.min('md')`
    align-items: end;
    display: grid;
    grid-gap: ${units(4)};
    grid-template-areas: 'copyright pagesLinks socialLinks';
    grid-template-columns: 2fr 3fr 2fr;
    padding: ${units(10)} 0 ${units(12)};
  `}

  ${media.min('lg')`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

/**
 * `PagesLinksWrapper` styled component.
 */

const PagesLinksWrapper = styled.div`
  align-items: end;
  display: grid;
  grid-area: pagesLinks;
  grid-gap: ${units(3)};
  grid-template-columns: 1fr;
  width: 100%;

  ${media.min('xs')`
    grid-template-columns: 1fr 1fr;
  `}
`;

/**
 * `FooterLabel` styled component.
 */

const FooterLabel = styled.p`
  font-family: ${theme('typography.fontFamily.saira')};
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 20px;
`;

/**
 * `Footer` component.
 */

function Footer({ children }: Props): Node {
  const { footerMenu, image } = useStaticQuery(footerQuery);
  const links = footerMenu?.nodes[0]?.content?.list;
  const linksGridRows = Math.ceil(links.length / 2);
  const linksLists = chunk(links, linksGridRows);

  return (
    <FooterWrapper>
      <BackgroundImage fluid={image?.childImageSharp?.fluid} />

      <Box position={'relative'}>
        {children}
      </Box>

      <Box
        as={'footer'}
        textAlign={'center'}
        textAlignMd={'initial'}
      >
        <Container>
          {children && <Divider />}

          <Grid>
            <PagesLinksWrapper>
              {linksLists.map((list, index) => (
                <ul key={index}>
                  {list.map(({ openInNewTab, pageUrl, title, url }) => (
                    <li key={title}>
                      <Link
                        {...pageUrl ? { to: pageUrl } : {
                          href: url,
                          rel: 'noopener',
                          target: openInNewTab ? '_blank' : '_self'
                        }}
                      >
                        {title}
                      </Link>
                    </li>
                  ))}
                </ul>
              ))}
            </PagesLinksWrapper>

            <Box
              gridArea={'socialLinks'}
              textAlignMd={'right'}
            >
              <SocialLinks />
            </Box>

            <Box
              gridArea={'copyright'}
              order={3}
            >
              <FooterLabel>
                {`© ${new Date().getFullYear()}`}
              </FooterLabel>

              <FooterLabel>
                {'Fintech Server Global Inc.'}
              </FooterLabel>
            </Box>
          </Grid>
        </Container>
      </Box>
    </FooterWrapper>
  );
}

/**
 * Export `Footer` component.
 */

export default Footer;
