// @flow

/**
 * Module dependencies.
 */

import { Elastic } from 'react-burgers';
import { color, states, themeProp } from '@seegno-labs/react-components/styles';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  active: boolean,
  onClick: Function
};

/**
 * `NaviconButton` styled component.
 */

const NaviconButton = styled(Elastic)`
  color: ${color('grey700')};
  transition: color ${themeProp('animations.defaultTransition')};
  transition-delay: 0s;

  &.BurgerActive {
    color: ${color('white')};
    transition-delay: 0.3s;
  }

  ${states.interaction`
    color: ${color('grey500')};
  `}
`;

/**
 * `Navicon` component.
 */

const Navicon = (props: Props): Node => (
  <NaviconButton
    {...props}
    color={'currentColor'}
    lineHeight={1}
    lineSpacing={6}
    padding={'5px 0 0'}
    width={30}
  />
);

/**
 * Export `Navicon` component.
 */

export default Navicon;
