// @flow

/**
 * Module dependencies.
 */

import { type Node, useEffect } from 'react';

/**
 * `Props` type.
 */

type Props = {
  off: boolean
};

/**
 * `BodyScroll` component.
 */

const BodyScroll = ({ off }: Props): Node => {
  useEffect(() => {
    const body = document.body;

    if (!body || !off) {
      return;
    }

    body.style.overflow = 'hidden';

    return () => {
      body.style.overflow = '';
    };
  }, [off]);

  return null;
};

/**
 * Export `BodyScroll` component.
 */

export default BodyScroll;
