// @flow

/**
 * Module dependencies.
 */

import { color, units } from '@seegno-labs/react-components/styles';
import styled from 'styled-components';

/**
 * Export `Card` styled component.
 */

export const Card = styled.div`
  background-color: ${color('grey100')};
  border-radius: ${units(1)};
  box-shadow: 0 2px 4px 0 ${color.transparentize('secondary', 0.13)}, 8px 12px 15px 0 ${color.transparentize('secondary', 0.08)};
`;
