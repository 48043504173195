// @flow

/**
 * Module dependencies.
 */

import { Container } from 'components/core/layout';
import { type ItemLink } from './links-list';
import { color, media, themeProp, units } from '@seegno-labs/react-components/styles';
import { ifProp } from 'styled-tools';
import { isHash } from 'utils/url-resolver';
import BodyScroll from 'components/core/body-scroll';
import Link from 'components/core/links/link';
import React, { type Node } from 'react';
import RouterLink from 'components/core/links/router-link';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  closeMenu: () => void,
  isOpen: boolean,
  links: Array<ItemLink>
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('secondary')};
  color: ${color('white')};
  height: 100vh;
  left: -100vw;
  margin-left: 0;
  overflow-y: auto;
  padding: ${units(10)} 0;
  position: fixed;
  top: 0;
  transition: ${themeProp('animations.defaultTransition')};
  transition-property: transform, visibility;
  width: 100vw;
  z-index: ${themeProp('zIndex.mobileMenu')};

  ${ifProp('isOpen', css`
    transform: translateX(100%);
    visibility: visible;
  `, css`
    pointer-events: none;
    transform: translateX(0);
    visibility: hidden;
  `)}

  ${media.min('md')`
    display: none;
  `}
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

/**
 * `ListItem` styled component.
 */

const ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: ${units(1)};
  }
`;

/**
 * `ListItemLink` styled component.
 */

const ListItemLink = styled(Link)`
  ${themeProp('typography.styles.h3')}

  &[aria-current='page'] {
    opacity: 0.25;
  }
`;

/**
 * `MobileMenu` component.
 */

const MobileMenu = ({ closeMenu, isOpen, links }: Props): Node => (
  <>
    <BodyScroll off={isOpen} />

    <Wrapper isOpen={isOpen}>
      <StyledContainer>
        <ul>
          {links.map(({ href, label, target, to }) => (!!href || !!to) && (
            <ListItem key={label}>
              <ListItemLink
                as={to ? RouterLink : null}
                colorTheme={'white'}
                href={href}
                onClick={href ? null : closeMenu}
                rel={href && !isHash(href) ? 'noopener' : null}
                target={target ?? (href && !isHash(href) ? '_blank' : null)}
                to={to}
              >
                {label}
              </ListItemLink>
            </ListItem>
          ))}
        </ul>
      </StyledContainer>
    </Wrapper>
  </>
);

/**
 * Default props.
 */

MobileMenu.defaultProps = {
  links: []
};

/**
 * Export `MobileMenu` component.
 */

export default MobileMenu;
