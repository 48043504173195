// @flow

/**
 * Module dependencies.
 */

import { throttle } from 'lodash';
import { useEffect, useState } from 'react';

/**
 * Use scroll.
 */

function useScroll() {
  const [state, setState] = useState({ xPos: 0, yPos: 0 });

  useEffect(() => {
    const onScroll = throttle(() => {
      setState({
        xPos: window.scrollX,
        yPos: window.scrollY
      });
    }, 250);

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return state;
}

/**
 * Export `useScroll`.
 */

export default useScroll;
