// @flow

/**
 * Module dependencies.
 */

import { Box, Container } from 'components/core/layout';
import { Card } from 'components/core/card';
import { Icon } from '@seegno-labs/react-components/media';
import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import { graphql, useStaticQuery } from 'gatsby';
import { ifProp, theme } from 'styled-tools';
import Button from 'slyk-design-system/button';
import Cookies from 'js-cookie';
import Interpolate from 'components/core/interpolate';
import React, { type Node, useEffect, useState } from 'react';
import cookiesIcon from 'assets/svg/cookies.svg';
import styled, { css } from 'styled-components';
import useTranslate from 'hooks/use-translate';

/**
 * Navbar links query.
 */

const navbarLinksQuery = graphql`
  query {
    privacyPolicy: seegnoCmsPrivacyPolicyPage {
      path
    }
  }
`;

/**
 * Cookies GDPR acceptance key.
 */

const cookieKey = 'gdpr-analytics-enabled';

/**
 * `Banner` styled component.
 */

const Banner = styled.div`
  bottom: ${units(2)};
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  z-index: ${theme('zIndex.cookiesBanner')};
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(Card)`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: ${units(3)};
  justify-content: center;
  padding: ${units(3)};
  text-align: center;
  transition: clip-path ${theme('animations.slowTransition')};

  ${ifProp('visible', css`
    clip-path: circle(100%);
    pointer-events: all;
  `, css`
    clip-path: circle(0);
    pointer-events: none;
  `)}

  ${media.min('sm')`
    display: grid;
    grid-template-areas: 'icon message actions';
    grid-template-columns: max-content 1fr max-content;
    justify-content: space-between;
    padding: ${units(2)} ${units(3)};
    text-align: left;
  `}

  ${media.min('lg')`
    padding: ${units(1)} ${units(3)};
  `}
`;

/**
 * `NoticeText` styled component.
 */

const NoticeText = styled(Type.Paragraph)`
  align-self: center;
  grid-area: message;
  padding-right: ${units(2)};
`;

/**
 * `StyledIcon` styled component.
 */

const StyledIcon = styled(Icon)`
  color: ${color('primary')};
  grid-area: icon;
`;

/**
 * `Link` styled component.
 */

const Link = styled.a`
  color: ${color('primary')};
  text-decoration: underline;
  transition: color ${theme('animations.defaultTransition')};

  :focus,
  :hover {
    color: ${color('blue700')};
  }
`;

/**
 * `Actions` styled component.
 */

const Actions = styled.div`
  grid-area: actions;
`;

/**
 * `PrivacyPolicyLink` component.
 */

function PrivacyPolicyLink({ children }): Node {
  const queryData = useStaticQuery(navbarLinksQuery);

  return (
    <Link
      href={queryData?.privacyPolicy?.path}
      key={queryData?.privacyPolicy?.path}
      rel={'noopener'}
      target={'_blank'}
    >
      {children}
    </Link>
  );
}

/**
 * `DataProtectionBanner` component.
 */

function DataProtectionBanner(): Node {
  const [isDirty, setIsDirty] = useState(true);
  const translate = useTranslate();
  const handleAgree = () => {
    setIsDirty(true);
    Cookies.set(cookieKey, true);

    if (window?.trackGoogleAnalytics) {
      window.trackGoogleAnalytics();
    }
  };

  const handleDisagree = () => {
    setIsDirty(true);
    Cookies.set(cookieKey, false);
  };

  useEffect(() => {
    if (Cookies.get(cookieKey) === undefined) {
      setTimeout(() => {
        setIsDirty(false);
      }, 1500);
    }
  }, []);

  return (
    <Banner>
      <Container>
        <Wrapper visible={!isDirty}>
          <StyledIcon
            icon={cookiesIcon}
            size={units(6)}
          />

          <NoticeText>
            <Interpolate
              components={[PrivacyPolicyLink]}
              keyPath={'gdprBanner.message'}
            />
          </NoticeText>

          <Actions>
            <Box
              display={'inline-block'}
              marginRight={units(2)}
            >
              <Button
                colorTheme={'primary'}
                onClick={handleAgree}
                size={'medium'}
              >
                {translate('gdprBanner.accept')}
              </Button>
            </Box>

            <Button
              colorTheme={'grey'}
              onClick={handleDisagree}
              size={'medium'}
              variant={'outline'}
            >
              {translate('gdprBanner.decline')}
            </Button>
          </Actions>
        </Wrapper>
      </Container>
    </Banner>
  );
}

/**
 * Export `DataProtectionBanner` component.
 */

export default DataProtectionBanner;
