// @flow

/**
 * Module dependencies.
 */

import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import React, { type Node } from 'react';

/**
 * Export `MetatagsProps` type.
 */

export type MetatagsProps = {|
  description?: ?string,
  keywords?: ?string,
  title?: ?string
|};

/**
 * Metadata query.
 */

const metadataQuery = graphql`
  query {
    seegnoCmsSetting(
      code: {
        eq: "socialPic"
      }
    ) {
      file {
        value {
          url
        }
      }
    }

    site {
      siteMetadata {
        author
        defaultLang
        description
        fbPageId
        ogImageAlt
        siteUrl
        title
        titleTemplate
        twitterAccountId
        version
      }
    }
  }
`;

/**
 * `Metatags` component.
 */

const Metatags = (props: MetatagsProps): Node => {
  const { description, keywords, title } = props;
  const data = useStaticQuery(metadataQuery);
  const defaultMetatags = data.site.siteMetadata;
  const htmlAttributes = { lang: 'en' || defaultMetatags.defaultLang };
  const image = data?.seegnoCmsSetting?.file?.value?.url;
  const isProductionEnv = process.env.NODE_ENV === 'production'; // eslint-disable-line no-process-env

  return (
    <Helmet
      defaultTitle={defaultMetatags.title}
      htmlAttributes={htmlAttributes}
      titleTemplate={defaultMetatags.titleTemplate}
    >
      {title && (
        <title>
          {title}
        </title>
      )}

      {!isProductionEnv && (
        <meta
          content={'noindex'}
          name={'robots'}
        />
      )}

      {!isProductionEnv && (
        <meta
          content={'noindex'}
          name={'AdsBot-Google'}
        />
      )}

      <meta
        content={description || defaultMetatags.description}
        name={'description'}
      />

      {keywords && (
        <meta
          content={keywords}
          name={'keywords'}
        />
      )}

      <meta
        content={defaultMetatags.author}
        name={'author'}
      />

      {!!image && (
        <meta
          content={image}
          name={'image'}
        />
      )}

      <meta
        content={'website'}
        property={'og:type'}
      />

      <meta
        content={title || defaultMetatags.title}
        property={'og:title'}
      />

      <meta
        content={description || defaultMetatags.description}
        property={'og:description'}
      />

      {!!image && (
        <meta
          content={image}
          property={'og:image'}
        />
      )}

      <meta
        content={defaultMetatags.ogImageAlt}
        property={'og:image:alt'}
      />

      <meta
        content={defaultMetatags.siteUrl}
        property={'og:url'}
      />

      <meta
        content={defaultMetatags.title}
        property={'og:site_name'}
      />

      <meta
        content={'website'}
        property={'og:type'}
      />

      <meta
        content={title || defaultMetatags.title}
        property={'twitter:title'}
      />

      <meta
        content={description || defaultMetatags.description}
        property={'twitter:description'}
      />

      <meta
        content={defaultMetatags.author}
        property={'twitter:site'}
      />

      <meta
        content={defaultMetatags.author}
        property={'twitter:creator'}
      />

      <meta
        content={defaultMetatags.twitterAccountId}
        property={'twitter:account_id'}
      />

      <meta
        content={'summary_large_image'}
        name={'twitter:card'}
      />

      {!!image && (
        <meta
          content={image}
          property={'twitter:image'}
        />
      )}

      {defaultMetatags.fbPageId && (
        <meta
          content={defaultMetatags.fbPageId}
          property={'fb:pages'}
        />
      )}

      <meta
        content={defaultMetatags.version}
        name={'version'}
      />
    </Helmet>
  );
};

/**
 * Export `Metatags` component.
 */

export default Metatags;
