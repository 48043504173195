// @flow

/**
 * Module dependencies.
 */

import { get, split } from 'lodash';
import { parse } from 'url';

/**
 * Export `isHash`.
 */

export function isHash(url: ?string): boolean {
  if (!url) {
    return false;
  }

  return url.startsWith('#');
}

/**
 * Export `slykDomainUrlResolver`.
 */

export function slykDomainUrlResolver(appBaseurl: ?string, domainSlug: string, hasProtocol?: boolean) {
  if (!appBaseurl) {
    return;
  }

  const url = parse(appBaseurl);
  const hostname = get(url, 'hostname');
  const protocol = get(url, 'protocol');
  const [, domain] = split(hostname, 'app.');

  if (hasProtocol) {
    return `${protocol}//${domainSlug}.${domain}`;
  }

  return `${domainSlug}.${domain}`;
}
