// @flow

/**
 * Module dependencies.
 */

import { ModalContext, modals } from 'components/core/modal/provider';
import { useLocation } from '@reach/router';
import DataProtectionBanner from './data-protection-banner';
import EarlyAccessModal from 'components/early-access-modal';
import Footer from 'components/footer';
import Metatags, { type MetatagsProps } from 'components/metatags';
import Navbar from 'components/navbar';
import React, { type Node, useContext, useEffect } from 'react';
import SlykMask from 'components/core/slyk-mask';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  children: Node,
  footerElement?: Node,
  metatags: MetatagsProps
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
`;

/**
 * `PageContainer` components.
 */

const PageContainer = (props: Props): Node => {
  const { children, footerElement, metatags, ...rest } = props;
  const { activeModal, closeModal, openModal } = useContext(ModalContext);
  const location = useLocation();

  // Open subscribe modal.
  useEffect(() => {
    if (location.hash === '#get-early-access') {
      openModal(modals.subscribe);
    }
  }, [location, openModal]);

  return (
    <>
      <Wrapper {...rest}>
        <SlykMask />

        <Metatags {...metatags} />

        <Navbar />

        <DataProtectionBanner />

        {children}

        <Footer>
          {footerElement}
        </Footer>
      </Wrapper>

      <EarlyAccessModal
        isOpen={activeModal === modals.subscribe}
        onClose={closeModal}
      />
    </>
  );
};

/**
 * Export `PageContainer` component.
 */

export default PageContainer;
